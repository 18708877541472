<template>
  <div>
    <v-card>
      <v-form ref="form" class="multi-col-validation">
        <v-toolbar dense dark color="primary">
          <v-toolbar-title><h4 class="font-weight-light">PURCHASE AUTO</h4>
          </v-toolbar-title>
        </v-toolbar>

        <v-layout row wrap class="align-center mx-2 mt-5">
          <v-flex xs12 md4>
            <v-select
              class="mx-2"
              v-model="particulars"
              dense
              outlined
              label="Car Code"
              :items="particulars_items"
              item-value="car_code"
              item-text="car_code"
              :rules="rules.combobox_rule"
              @change="selected_pending"
            ></v-select>
          </v-flex>
          <v-flex xs12 md12>
            <v-text-field
              class="mx-2"
              v-model="brand"
              dense
              label="Brand/Company"
              outlined
              readonly
            ></v-text-field>
            <v-text-field
              class="mx-2"
              v-model="type"
              dense
              label="Type"
              outlined
              readonly
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md12>
            <v-text-field
              class="mx-2"
              v-model="year_model"
              dense
              label="Year Model"
              outlined
              readonly
            ></v-text-field>
            <v-text-field
              class="mx-2"
              v-model="plate_no"
              dense
              label="Plate #/ Color"
              outlined
            ></v-text-field>
            <v-text-field
              class="mx-2"
              v-model="user"
              dense
              label="User"
              outlined
            ></v-text-field>
            <v-text-field
              class="mx-2"
              v-model="specify"
              dense
              label="Specify"
              outlined
              :rules="rules.default_max_255_character_and_no_empty_rule"
            ></v-text-field>
          </v-flex>
        </v-layout>

        <v-row>
          <!-- alert -->
          <v-col cols="12" v-show="alert">
            <v-alert
              color="warning"
              text
              class="mb-0"
            >
              <div class="d-flex align-start">
                <v-icon color="warning">
                  {{ icons.mdiAlertOutline }}
                </v-icon>

                <div class="ms-3">
                  <p class="text-base font-weight-medium mb-1">
                    {{alert_message}}
                  </p>
                </div>
              </div>
            </v-alert>
          </v-col>
          <v-col cols="12" md="12">
            <v-btn
              class="w-full"
              color="primary"
              @click="done_request"
              v-if="!saving_request"
            >
              Done
            </v-btn>
            <v-progress-circular
              :size=50
              :width="5"
              color="primary"
              indeterminate
              v-else
            ></v-progress-circular>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </div>
</template>
<script>

  import {mdiAlertOutline} from "@mdi/js";
  import {mapActions, mapGetters} from "vuex";

  const initialState = () => {
    return {
      saving_request: false,
      alert: false,
      alert_message: '',

      particulars: '',
      particulars_items: [],
      user: '',
      plate_no: '',
      year_model: '',
      type: '',
      brand: '',
      specify: '',
    }
  }
  export default {
    props: {
      category_id: Number,
      branch_id: Number,
      particulars_id: Number,
    },
    setup() {
      return {
        icons: {
          mdiAlertOutline,
        },
      }
    },
    data() {
      return initialState();
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
    },
    mounted() {
      const data = new FormData()
      data.append('category_id', this.category_id);
      data.append('branch_id', this.branch_id);
      this.list_of_car_unpaid(data)
        .then(response => {
          this.particulars_items = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    methods: {
      ...mapActions('inventory', ['list_of_car_unpaid']),
      selected_pending(value) {
        var index = this.particulars_items.map(function (x) {
          return x.car_code;
        }).indexOf(value)
        if (index != -1) {
          this.brand = this.particulars_items[index].auto_dealer
          this.user = this.particulars_items[index].specify_remarks
          this.plate_no = this.particulars_items[index].auto_unit_color + ' (' + this.particulars_items[index].auto_plate_no + ')'
          this.year_model = this.particulars_items[index].year_model
          this.type = this.particulars_items[index].auto_unit
        }
      },
      done_request() {
        this.saving_request = true
        this.alert = false
        if (this.$refs.form.validate()) {
          this.$emit('data', 'Brand-' + this.brand + ' # Type-' + this.type + ' # Year-' + this.year_model + ' # Color/Plate-' + this.plate_no + ' # User-' + this.user + ' # Specify-' + this.specify)
          this.saving_request = false
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving_request = false
        }
      },
    }
  }
</script>
