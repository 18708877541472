<template>
  <div>
    <v-card>
      <v-form ref="form" class="multi-col-validation">
        <v-toolbar dense dark color="primary">
          <v-toolbar-title><h4 class="font-weight-light">CLAIMS</h4>
          </v-toolbar-title>
        </v-toolbar>

        <v-layout row wrap class="align-center mx-2 mt-5">
          <v-flex xs12 md12>
            <v-radio-group
              v-model="row2"
              row
              dense
              class="mx-2"
            >
              <v-radio
                label="Is Rendered?"
                value="is_rendered"
              ></v-radio>
              <v-radio
                label="Not Rendered?"
                value="not_rendered"
              ></v-radio>
            </v-radio-group>
          </v-flex>
          <v-flex xs12 md4 v-if="row2==='is_rendered'">
            <v-select
              class="mx-2"
              v-model="particulars"
              dense
              outlined
              label="Pending With Claims"
              :items="particulars_items"
              item-value="maf_no"
              item-text="maf_no"
              :rules="rules.combobox_rule"
              @change="selected_pending"
            ></v-select>
          </v-flex>
          <v-flex xs12 md12 v-if="row2==='is_rendered'">
            <v-radio-group
              v-model="row"
              row
              dense
              class="mx-2"
              readonly
            >
              Claims to Renders:
              <v-radio
                label="Member"
                value="is_member"
              ></v-radio>
              <v-radio
                label="Dependent"
                value="is_dependent"
              ></v-radio>
            </v-radio-group>
          </v-flex>
          <v-flex xs12 md12 v-else>
            <v-radio-group
              v-model="row"
              row
              dense
              class="mx-2"
              @change="selected_pending"
            >
              Claims to Renders:
              <v-radio
                label="Member"
                value="is_member"
              ></v-radio>
              <v-radio
                label="Dependent"
                value="is_dependent"
              ></v-radio>
            </v-radio-group>
          </v-flex>
          <v-flex xs12 md4>
            <v-text-field
              v-if="row2==='is_rendered'"
              class="mx-2"
              v-model="maf_no"
              dense
              label="Maf #"
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
              readonly
            ></v-text-field>
            <v-text-field
              v-else
              class="mx-2"
              v-model="maf_no"
              dense
              label="Maf #"
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md8>
            <v-text-field
              v-if="row2==='is_rendered'"
              class="mx-2"
              v-model="members_name"
              dense
              label="Members Name"
              outlined
              :rules="rules.default_max_255_character_and_no_empty_rule"
              readonly
            ></v-text-field>
            <v-text-field
              v-else
              class="mx-2"
              v-model="members_name"
              dense
              label="Members Name"
              outlined
              :rules="rules.default_max_255_character_and_no_empty_rule"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md8 v-if="row==='is_dependent'">
            <v-text-field
              class="mx-2"
              v-model="dependent_name"
              dense
              label="Dependent Name"
              outlined
              :rules="rules.default_max_255_character_and_no_empty_rule"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md12>
            <v-select
              class="mx-2"
              v-model="relationship"
              dense
              outlined
              label="Relationship to the Policy Holder"
              :items="relationship_items"
              :rules="rules.combobox_rule"
            ></v-select>
          </v-flex>
          <v-flex xs12 md8 class="mt-15">
            <v-text-field
              class="mx-2"
              v-model="claimant_name"
              dense
              label="Claimant Name"
              outlined
              :rules="rules.default_max_255_character_and_no_empty_rule"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md12>
            <v-select
              class="mx-2"
              v-model="relationship_d"
              dense
              outlined
              label="Relationship to the Deceased"
              :items="relationship_d_items"
              :rules="rules.combobox_rule"
            ></v-select>
          </v-flex>
          <v-flex xs12 md8 v-if="relationship_d==='Others'">
            <v-text-field
              class="mx-2"
              v-model="specify"
              dense
              label="Specify"
              outlined
              :rules="rules.default_max_100_character_and_no_empty_rule"
            ></v-text-field>
          </v-flex>

        </v-layout>

        <v-row>
          <!-- alert -->
          <v-col cols="12" v-show="alert">
            <v-alert
              color="warning"
              text
              class="mb-0"
            >
              <div class="d-flex align-start">
                <v-icon color="warning">
                  {{ icons.mdiAlertOutline }}
                </v-icon>

                <div class="ms-3">
                  <p class="text-base font-weight-medium mb-1">
                    {{alert_message}}
                  </p>
                </div>
              </div>
            </v-alert>
          </v-col>
          <v-col cols="12" md="12">
            <v-btn
              class="w-full"
              color="primary"
              @click="done_request"
              v-if="!saving_request"
            >
              Done
            </v-btn>
            <v-progress-circular
              :size=50
              :width="5"
              color="primary"
              indeterminate
              v-else
            ></v-progress-circular>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </div>
</template>
<script>

  import {mdiAlertOutline} from "@mdi/js";
  import {mapActions, mapGetters} from "vuex";

  const initialState = () => {
    return {
      saving_request: false,
      alert: false,
      alert_message: '',

      particulars: '',
      particulars_items: [],
      row2: '',
      row: '',
      relationship: '',
      relationship_d: '',
      relationship_d_items: [],
      claimant_name: '',
      relationship_items: [],
      dependent_name: '',
      members_name: '',
      maf_no: '',
      specify: '',
    }
  }
  export default {
    props: {
      category_id: Number,
      branch_id: Number,
      particulars_id: Number,
    },
    setup() {
      return {
        icons: {
          mdiAlertOutline,
        },
      }
    },
    data() {
      return initialState();
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
    },
    mounted() {
      const data = new FormData()
      data.append('category_id', this.category_id);
      data.append('branch_id', this.branch_id);
      this.list_of_pending_claims(data)
        .then(response => {
          this.particulars_items = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    methods: {
      ...mapActions('request_data', ['check_revolving_fund_availability', 'list_of_pending_claims']),
      selected_pending(value) {
        var index = this.particulars_items.map(function (x) {
          return x.maf_no;
        }).indexOf(value)
        if (index != -1) {
          this.dependent_name = ''
          this.members_name = ''
          this.relationship_items = []
          this.maf_no = this.particulars_items[index].maf_no
          var splited = this.particulars_items[index].description.split(' # ')
          if (splited[0] === 'Member') {
            this.row = 'is_member'
          } else if (splited[0] === 'Dependent') {
            this.row = 'is_dependent'
            this.dependent_name = splited[3].replace('Cadaver-', '')
          }
          this.members_name = splited[2].replace('MemName-', '')
        }
        if (this.row === 'is_member') {
          this.relationship_items = ['Owner of Policy']
          this.relationship_d_items = ['Mother', 'Father', 'Son', 'Daughter', 'Wife', 'Husband', 'Sister', 'Brother', 'Others']
        } else if (this.row === 'is_dependent') {
          this.relationship_items = ['Mother', 'Father', 'Son', 'Daughter', 'Wife', 'Husband', 'Sister', 'Brother']
          this.relationship_d_items = ['Owner of Policy', 'Mother', 'Father', 'Son', 'Daughter', 'Wife', 'Husband', 'Sister', 'Brother', 'Others']
        }
      },
      done_request() {
        this.saving_request = true
        this.alert = false
        if (this.$refs.form.validate()) {
          this.$emit('data', (this.row === 'is_member' ? 'Member' : 'Dependent') + ' # Mafno.-' + this.maf_no + ' # Name-' + (this.row === 'is_member' ? this.members_name : this.dependent_name) + ' # ' + this.relationship + ' # Claimant-' + this.claimant_name + ' # ' + this.relationship_d + ' # ' + (this.row2 === 'is_rendered' ? 'Rendered' : 'UnRendered')+ ' # Mem-' + (this.members_name))
          this.saving_request = false
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving_request = false
        }
      },
    }
  }
</script>
