<template>
  <div>
    <v-card>
      <v-form ref="form" class="multi-col-validation">
        <v-toolbar dense dark color="primary">
          <v-toolbar-title><h4 class="font-weight-light">SALARIES AND WAGES</h4>
          </v-toolbar-title>
        </v-toolbar>

        <v-layout row wrap class="align-center mx-2 mt-5">
          <v-flex xs12 md4>
            <v-select
              class="mx-2"
              v-model="category"
              dense
              outlined
              label="Category"
              :items="['Salary','SSS','SSS Loan','PhilHealth','Pag-Ibig','Pag-Ibig Loan','Tax','Cash Bond','Coop Loans','Coop Savings','Damayan Plan','Cash Advance','Accounting Loan','Auto Loan','Motor Finance','Others','Other Receivable']"
              :rules="rules.combobox_rule"
              @change="check_availability"
            ></v-select>
          </v-flex>
          <v-flex xs12 md4>
            <v-select
              class="mx-2"
              v-model="type_of_employee"
              dense
              outlined
              label="Type of Employee"
              :items="['Active','Temporary Deactivate','Permanent Deactivate']"
              :rules="rules.combobox_rule"
              @change="check_availability"
            ></v-select>
          </v-flex>
          <v-flex xs12 md4>
            <v-select
              class="mx-2"
              v-model="month_of"
              dense
              outlined
              label="Month of"
              :items="month_of_items"
              item-text="month_of"
              item-value="month_of"
              :rules="rules.combobox_rule"
              @change="selected_month"
            ></v-select>
          </v-flex>
          <v-flex xs12 md4>
            <v-select
              class="mx-2"
              v-model="duration"
              dense
              outlined
              label="Duration"
              :items="duration_items"
              item-text="duration"
              item-value="duration"
              :rules="rules.combobox_rule"
              @change="selected_duration"
            ></v-select>
          </v-flex>
          <v-flex xs12 md4>
            <v-select
              class="mx-2"
              v-model="bank"
              dense
              outlined
              label="Bank"
              :items="bank_items"
              item-text="bank_type"
              item-value="bank_type"
              :rules="rules.combobox_rule"
              @change="selected_bank"
            ></v-select>
          </v-flex>
          <v-flex xs12 md4>
            <v-select
              class="mx-2"
              v-model="batch"
              dense
              outlined
              label="Batch #"
              :items="batch_items"
              item-text="batch_no"
              item-value="batch_no"
              :rules="rules.combobox_rule"
              @change="selected_batch"
            ></v-select>
          </v-flex>
          <v-flex xs12 md4 v-if="type_of_employee!='Active'">
            <v-select
              class="mx-2"
              v-model="name"
              dense
              outlined
              label="Name"
              :items="name_items"
              item-text="name"
              item-value="id"
              :rules="rules.combobox_rule"
              @change="selected_name"
            ></v-select>
          </v-flex>
          <v-flex xs12 md4>
            <v-text-field v-model="total_amount"
                          class="mx-2"
                          dense
                          outlined
                          label="Total Amount"
                          readonly
            ></v-text-field>
          </v-flex>
        </v-layout>

        <v-row>
          <!-- alert -->
          <v-col cols="12" v-show="alert">
            <v-alert
              color="warning"
              text
              class="mb-0"

            >
              <div class="d-flex align-start">
                <v-icon color="warning">
                  {{ icons.mdiAlertOutline }}
                </v-icon>

                <div class="ms-3">
                  <p class="text-base font-weight-medium mb-1">
                    {{alert_message}}
                  </p>
                </div>
              </div>
            </v-alert>
          </v-col>
          <v-col cols="12" md="12">
            <v-btn
              class="w-full"
              color="primary"
              @click="done_request"
              v-if="!saving_request"
            >
              Done
            </v-btn>
            <v-progress-circular
              :size=50
              :width="5"
              color="primary"
              indeterminate
              v-else
            ></v-progress-circular>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </div>
</template>
<script>

  import {mdiAlertOutline} from "@mdi/js";
  import {mapActions, mapGetters} from "vuex";

  const initialState = () => {
    return {
      saving_request: false,
      alert: false,
      alert_message: '',
      category: '',
      type_of_employee: '',
      specify: '',
      month_of: '',
      month_of_items: [],
      name: '',
      name_items: [],
      duration: '',
      duration_items: [],
      bank: '',
      bank_items: [],
      batch: '',
      batch_items: [],
      total_amount: '',
      total_amount_raw: '',
    }
  }
  export default {
    props: {
      category_id: Number,
      branch_id: Number,
    },
    setup() {
      return {
        icons: {
          mdiAlertOutline,
        },
      }
    },
    data() {
      return initialState();
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
    },
    methods: {
      ...mapActions('salaries_and_wages', ['check_availability_for_request']),
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      check_availability() {
        const data = new FormData()
        data.append('category_id', this.category_id);
        data.append('branch_id', this.branch_id);
        data.append('type_of_employee', this.type_of_employee);
        data.append('category', this.category);
        this.check_availability_for_request(data)
          .then(response => {
            this.month_of_items = response.data
            this.month_of = ''
            this.duration = ''
            this.duration_items = []
            this.bank = ''
            this.bank_items = []
            this.batch = ''
            this.batch_items = []
            this.total_amount = ''
            this.total_amount_raw = ''
            this.name = ''
            this.name_items = []
          })
          .catch(error => {
            console.log(error)
          })
      },
      selected_month(value) {
        var index = this.month_of_items.map(function (x) {
          return x.month_of;
        }).indexOf(value)
        if (index != -1) {
          this.duration_items = this.month_of_items[index].data_duration
          this.duration = ''
          this.bank = ''
          this.bank_items = []
          this.batch = ''
          this.batch_items = []
          this.total_amount = ''
          this.total_amount_raw = ''
          this.name = ''
          this.name_items = []
        }
      },
      selected_duration(value) {
        var index = this.duration_items.map(function (x) {
          return x.duration;
        }).indexOf(value)
        if (index != -1) {
          this.bank_items = this.duration_items[index].data_bank
          // this.bank_items.splice(0, 0, {
          //   id: -1,
          //   bank_type: 'All',
          // })
          this.bank = ''
          this.batch = ''
          this.batch_items = []
          this.total_amount = ''
          this.total_amount_raw = ''
          this.name = ''
          this.name_items = []
        }
      },
      selected_bank(value) {
        var index = this.bank_items.map(function (x) {
          return x.bank_type;
        }).indexOf(value)
        if (index != -1) {
          this.batch_items = this.bank_items[index].data_batch
          this.batch = ''
          this.total_amount = ''
          this.total_amount_raw = ''
          this.name = ''
          this.name_items = []
        }
      },
      selected_batch(value) {
        var index = this.batch_items.map(function (x) {
          return x.batch_no;
        }).indexOf(value)
        if (index != -1) {
          if (this.type_of_employee === 'Active') {
            this.total_amount = this.formatPrice(this.batch_items[index].amount)
            this.total_amount_raw = this.batch_items[index].amount
          } else {
            this.name = ''
            this.name_items = this.batch_items[index].names
            this.total_amount = ''
            this.total_amount_raw = ''
          }
        }
      },
      selected_name(value) {
        var index = this.name_items.map(function (x) {
          return x.id;
        }).indexOf(value)
        if (index != -1) {
          this.total_amount = this.formatPrice(this.name_items[index].total_amount)
          this.total_amount_raw = this.name_items[index].total_amount
        }
      },
      done_request() {
        this.saving_request = true
        this.alert = false
        if (this.$refs.form.validate()) {
          if (parseFloat(this.total_amount) === 0) {
            this.alert = true
            this.alert_message = 'ALREADY REQUESTED'
            this.saving_request = false
          } else {
            if (this.type_of_employee != 'Active') {
              this.$emit('data', this.category + ' # M.-' + this.month_of + ' # Dur.-' + this.duration + ' # Bank-' + this.bank + ' # Batch-' + this.batch + ' # Data-' + this.type_of_employee + ' # Emp-' + this.name + ' # Name-' + this.name_items
                [this.name_items.map(function (x) {
                return x.id;
              }).indexOf(this.name)].name)
              this.$emit('amount', this.total_amount_raw)
            } else {
              this.$emit('data', this.category + ' # M.-' + this.month_of + ' # Dur.-' + this.duration + ' # Bank-' + this.bank + ' # Batch-' + this.batch + ' # Data-' + this.type_of_employee)
              this.$emit('amount', this.total_amount_raw)
            }

          }
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving_request = false
        }
      },
    }
  }
</script>
