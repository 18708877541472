<template>
  <div>
    <v-card>
      <v-form ref="form" class="multi-col-validation">
        <v-toolbar dense dark color="primary">
          <v-toolbar-title><h4 class="font-weight-light">AUTO MAINTENANCE</h4>
          </v-toolbar-title>
        </v-toolbar>

        <v-layout row wrap class="align-center mx-2 mt-5">
          <v-flex xs12 md12>
            <v-flex xs12 md12>
              <v-select
                v-if="is_liquidation"
                class="mx-2"
                v-model="category_idd"
                dense
                label="Category"
                :items="category_items"
                item-value="id"
                item-text="category"
                :rules="rules.combobox_rule"
                @change="selected_category"
              ></v-select>
            </v-flex>
            <v-flex xs12 md12>
              <v-select
                class="mx-2"
                v-model="car"
                dense
                outlined
                label="Car"
                :items="car_items"
                item-value="auto_unit"
                item-text="auto_unit"
                :rules="rules.combobox_rule"
              ></v-select>
            </v-flex>
            <v-flex xs12 md12>
              <v-select
                class="mx-2"
                v-model="details"
                dense
                outlined
                label="Details"
                :items="details_items"
                item-value="details"
                item-text="details"
                :rules="rules.combobox_rule"
              ></v-select>
            </v-flex>
            <v-flex xs12 md12>
              <v-text-field
                class="mx-2"
                v-model="description"
                dense
                label="Description"
                outlined
                :rules="rules.default_max_100_character_and_no_empty_rule"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 md12>
              <v-text-field
                class="mx-2"
                v-model="date"
                dense
                label="Date"
                outlined
                :rules="rules.default_max_45_character_and_no_empty_rule"
                type="date"
              ></v-text-field>
            </v-flex>
          </v-flex>
        </v-layout>

        <v-row>
          <!-- alert -->
          <v-col cols="12" v-show="alert">
            <v-alert
              color="warning"
              text
              class="mb-0"
            >
              <div class="d-flex align-start">
                <v-icon color="warning">
                  {{ icons.mdiAlertOutline }}
                </v-icon>

                <div class="ms-3">
                  <p class="text-base font-weight-medium mb-1">
                    {{alert_message}}
                  </p>
                </div>
              </div>
            </v-alert>
          </v-col>
          <v-col cols="12" md="12">
            <v-btn
              class="w-full"
              color="primary"
              @click="done_request"
              v-if="!saving_request"
            >
              Done
            </v-btn>
            <v-progress-circular
              :size=50
              :width="5"
              color="primary"
              indeterminate
              v-else
            ></v-progress-circular>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </div>
</template>
<script>

  import {mdiAlertOutline} from "@mdi/js";
  import {mapActions, mapGetters} from "vuex";

  const initialState = () => {
    return {
      saving_request: false,
      alert: false,
      alert_message: '',
      particulars: '',
      date: '',
      car: '',
      car_items: [],
      details: '',
      category_idd: '',
      details_items: [],
      category_items: [],
      description: '',

      isLoading: false,
    }
  }
  export default {
    props: {
      is_liquidation: Boolean,
      category_id: Number,
      branch_id: Number,
      particulars_id: Number,
      particulars_description_items: Array,
    },
    setup() {
      return {
        icons: {
          mdiAlertOutline,
        },
      }
    },
    data() {
      return initialState();
    },
    mounted() {
      if (this.is_liquidation){
        this.initialize_salaries_employee()
          .then(response => {
            this.category_items = response.data[0].category
            this.category_items.splice(0, 1)
          })
          .catch(error => {
            console.log(error)
          })
      }else{
        this.list_of_car({
          category_id: this.category_id,
          branch_id: this.branch_id,
        })
          .then(response => {
            this.car_items = response.data
          })
          .catch(error => {
            console.log(error)
          })
      }
      this.list_of_auto_maintenance_details()
        .then(response => {
          this.details_items = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
    },
    methods: {
      ...mapActions('inventory', ['list_of_car','list_of_car_liquidation']),
      ...mapActions('salaries_and_wages', ['initialize_salaries_employee']),
      ...mapActions('auto_maintenance_details', ['list_of_auto_maintenance_details',]),
      selected_category() {
        this.list_of_car_liquidation({
          category_id: this.category_idd,
        })
          .then(response => {
            this.car_items = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      done_request() {
        this.saving_request = true
        this.alert = false
        if (this.$refs.form.validate()) {
          this.$emit('data', 'Purp.-'+this.details+'~'+this.description.toUpperCase()+' # Spec.'+this.car+' # Date-'+this.date)
          this.saving_request = false
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving_request = false
        }
      },
    }
  }
</script>
