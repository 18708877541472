<template>
  <div>
    <v-card>
      <v-form ref="form" class="multi-col-validation">
        <v-toolbar dense dark color="primary">
          <v-toolbar-title><h4 class="font-weight-light">EMPLOYEE LOAN</h4>
          </v-toolbar-title>
        </v-toolbar>

        <v-layout row wrap class="align-center mx-2 mt-5">
          <v-flex xs12 md12>
            <v-radio-group
              v-model="row"
              row
              dense
              class="mx-2"
              @change="reset"
            >

              <v-radio
                label="Employee"
                value="is_employee"
              ></v-radio>
              <v-radio
                label="Non-Employee"
                value="is_non_employee"
              ></v-radio>
            </v-radio-group>
          </v-flex>
          <v-flex xs12 md12>
            <v-select
              class="mx-2"
              v-model="name"
              dense
              outlined
              label="Name"
              :items="name_items"
              item-text="name"
              item-id="name"
              :rules="rules.combobox_rule"
              @change="selected_name"
            ></v-select>
          </v-flex>
          <v-flex xs12 md12>
            <v-text-field
              class="mx-2"
              v-model="purpose"
              dense
              label="Purpose"
              outlined
              readonly
            ></v-text-field>
          </v-flex>
        </v-layout>

        <v-row>
          <!-- alert -->
          <v-col cols="12" v-show="alert">
            <v-alert
              color="warning"
              text
              class="mb-0"
            >
              <div class="d-flex align-start">
                <v-icon color="warning">
                  {{ icons.mdiAlertOutline }}
                </v-icon>

                <div class="ms-3">
                  <p class="text-base font-weight-medium mb-1">
                    {{alert_message}}
                  </p>
                </div>
              </div>
            </v-alert>
          </v-col>
          <v-col cols="12" md="12">
            <v-btn
              class="w-full"
              color="primary"
              @click="done_request"
              v-if="!saving_request"
            >
              Done
            </v-btn>
            <v-progress-circular
              :size=50
              :width="5"
              color="primary"
              indeterminate
              v-else
            ></v-progress-circular>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </div>
</template>
<script>

  import {mdiAlertOutline} from "@mdi/js";
  import {mapActions, mapGetters} from "vuex";

  const initialState = () => {
    return {
      saving_request: false,
      alert: false,
      alert_message: '',

      row: 'is_employee',
      purpose: '',
      name: '',
      name_items: [],
      id: 0,
      amount: 0,
    }
  }
  export default {
    props: {
      category_id: Number,
      branch_id: Number,
      particulars_id: Number,
      particulars_description_items: Array,
    },
    setup() {
      return {
        icons: {
          mdiAlertOutline,
        },
      }
    },
    data() {
      return initialState();
    },
    mounted() {
      this.initialize_data()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
    },
    methods: {
      ...mapActions('loan_application', ['list_of_pending_loan']),
      initialize_data() {
        this.purpose = ''
        this.id = 0
        const data = new FormData()
        data.append('category', this.row);
        this.list_of_pending_loan(data)
          .then(response => {
            this.name_items = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      reset() {
        this.initialize_data()
      },
      selected_name(value) {
        var index = this.name_items.map(function (x) {
          return x.name;
        }).indexOf(value)
        if (index != -1) {
          this.purpose = this.name_items[index].purpose
          this.id = this.name_items[index].id
          this.amount = this.name_items[index].amount
        }
      },
      done_request() {
        this.saving_request = true
        this.alert = false
        if (this.$refs.form.validate()) {
          this.$emit('data', (this.row === 'is_employee' ? 'Emp.' : 'Non-Emp.') + ' # ID-' + this.id + ' # Name-' + this.name + ' # Purp.-' + this.purpose)
          this.$emit('amount', this.amount)
          this.saving_request = false
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving_request = false
        }
      },
    }
  }
</script>
