<template>
  <div>
    <v-card>
      <v-form ref="form" class="multi-col-validation">
        <v-toolbar dense dark color="primary">
          <v-toolbar-title><h4 class="font-weight-light">CALLERS</h4>
          </v-toolbar-title>
        </v-toolbar>

        <v-layout row wrap class="align-center mx-2 mt-5">
          <v-flex xs12 md12>
            <v-flex xs12 md3>
              <v-text-field
                class="mx-2"
                v-model="fsc_no"
                dense
                label="Fsc #"
                outlined
                :rules="rules.default_max_45_character_and_no_empty_rule"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 md12>
              <v-text-field
                class="mx-2"
                v-model="name"
                dense
                label="Name of Cadaver"
                outlined
                :rules="rules.default_max_100_character_and_no_empty_rule"
              ></v-text-field>
            </v-flex>
            <v-select
              class="mx-2"
              v-model="particulars"
              dense
              outlined
              label="Casket Type"
              :items=casket_type_items
              item-text="casket_type"
              item-value="casket_type"
              :rules="rules.combobox_rule"
            ></v-select>
          </v-flex>
          <v-flex xs12 md3>
            <v-text-field
              class="mx-2"
              v-model="casket_amount"
              dense
              label="Casket Amount"
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
              type="number"
              @keyup="calculate_commission"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md3>
            <v-text-field
              class="mx-2"
              v-model="callers_commision"
              dense
              label="Casket Amount"
              outlined
              readonly
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md12>
            <v-select
              class="mx-2"
              v-model="percentage"
              dense
              outlined
              label="Percentage"
              :items="['10%','15%']"
              :rules="rules.combobox_rule"
              @change="calculate_commission"
            ></v-select>
          </v-flex>
        </v-layout>

        <v-row>
          <!-- alert -->
          <v-col cols="12" v-show="alert">
            <v-alert
              color="warning"
              text
              class="mb-0"
            >
              <div class="d-flex align-start">
                <v-icon color="warning">
                  {{ icons.mdiAlertOutline }}
                </v-icon>

                <div class="ms-3">
                  <p class="text-base font-weight-medium mb-1">
                    {{alert_message}}
                  </p>
                </div>
              </div>
            </v-alert>
          </v-col>
          <v-col cols="12" md="12">
            <v-btn
              class="w-full"
              color="primary"
              @click="done_request"
              v-if="!saving_request"
            >
              Done
            </v-btn>
            <v-progress-circular
              :size=50
              :width="5"
              color="primary"
              indeterminate
              v-else
            ></v-progress-circular>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </div>
</template>
<script>

  import {mdiAlertOutline} from "@mdi/js";
  import {mapActions, mapGetters} from "vuex";

  const initialState = () => {
    return {
      saving_request: false,
      alert: false,
      alert_message: '',
      particulars: '',
      casket_type_items: [],
      particulars_items: ['New', 'Existing', 'Increase', 'Extension'],
      callers_commision: 0,
      name: '',
      fsc_no: '',

      isLoading: false,
      casket_amount: 0,
      particulars_description_id: 0,
      percentage: '',
    }
  }
  export default {
    props: {
      category_id: Number,
      branch_id: Number,
      particulars_id: Number,
    },
    setup() {
      return {
        icons: {
          mdiAlertOutline,
        },
      }
    },
    mounted() {
      this.list_of_casket()
        .then(response => {
          this.casket_type_items = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    data() {
      return initialState();
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
    },
    methods: {
      ...mapActions('request_data', ['list_of_casket', 'check_under_particulars_availability']),
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      calculate_commission() {
        var percenta = this.percentage.split('%')
        this.callers_commision = parseFloat(this.casket_amount) * (parseFloat(percenta[0]) / 100)
      },
      done_request() {
        this.saving_request = true
        this.alert = false
        if (this.$refs.form.validate()) {
          const data = new FormData()
          data.append('particulars_id', this.particulars_id);
          data.append('particulars', this.fsc_no);
          data.append('month_of', '');
          this.check_under_particulars_availability(data)
            .then(response => {
              if (response.data > 0) {
                this.alert = true
                this.alert_message = this.fsc_no + ' ALREADY ENCODED!!!'
                this.saving_request = false
              } else {
                this.$emit('data', 'FSCNo.-' + this.fsc_no + ' # ' + this.name + ' # ' + this.particulars + ' # P' + this.formatPrice(this.casket_amount) + ' # ' + this.percentage)
                this.saving_request = false
              }
            })
            .catch(error => {
              console.log(error)
            })
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving_request = false
        }
      },
    }
  }
</script>
