<template>
  <div>
    <v-card>
      <v-form ref="form" class="multi-col-validation">
        <v-toolbar dense dark color="primary">
          <v-toolbar-title><h4 class="font-weight-light">USAGE INFORMATION</h4>
          </v-toolbar-title>
        </v-toolbar>

        <v-layout row wrap class="align-center mx-2 mt-5">
          <v-flex xs12 md12 v-if="kind_of_bill==='ELECTRIC BILL' && category_id===4">
            <v-select
              class="mx-2"
              v-model="gross_collections"
              dense
              outlined
              label="Gross Collection"
              :items="['Php 50,000.00 below','Php 50,001.00 - Php 100,000.00','Php 100,001.00 - Php 200,000.00','Php 200,001.00 - Php 500,000.00','Php 500,001.00 - Php 1,000,000.00','Php 1,000,001.00 and above']"
              :rules="rules.combobox_rule"
            ></v-select>
          </v-flex>
          <v-flex xs12 md6 v-if="kind_of_bill==='ELECTRIC BILL'">
            <v-text-field
              class="mx-2"
              v-model="amount_per_usage"
              dense
              label="KWH (amount)"
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
              type="number"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md6 v-else>
            <v-text-field
              class="mx-2"
              v-model="amount_per_usage"
              dense
              label="Cubic (amount)"
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
              type="number"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md6 v-if="kind_of_bill==='ELECTRIC BILL'">
            <v-text-field
              class="mx-2"
              v-model="usage"
              dense
              label="KWH (usage)"
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
              type="number"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md6 v-else>
            <v-text-field
              class="mx-2"
              v-model="usage"
              dense
              label="Cubic (usage)"
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
              type="number"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md6>
            <v-text-field
              class="mx-2"
              v-model="other_charges"
              dense
              label="Other Charges"
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
              type="number"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md6 v-if="other_charges>0">
            <v-text-field
              class="mx-2"
              v-model="specify"
              dense
              label="Specify Charges"
              outlined
              :rules="rules.default_max_100_character_and_no_empty_rule"
            ></v-text-field>
          </v-flex>
        </v-layout>

        <v-row>
          <!-- alert -->
          <v-col cols="12" v-show="alert">
            <v-alert
              color="warning"
              text
              class="mb-0"
            >
              <div class="d-flex align-start">
                <v-icon color="warning">
                  {{ icons.mdiAlertOutline }}
                </v-icon>

                <div class="ms-3">
                  <p class="text-base font-weight-medium mb-1">
                    {{alert_message}}
                  </p>
                </div>
              </div>
            </v-alert>
          </v-col>
          <v-col cols="12" md="12">
            <v-btn
              class="w-full"
              color="primary"
              @click="done_request"
              v-if="!saving_request"
            >
              Done
            </v-btn>
            <v-progress-circular
              :size=50
              :width="5"
              color="primary"
              indeterminate
              v-else
            ></v-progress-circular>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </div>
</template>
<script>

  import {mdiAlertOutline} from "@mdi/js";
  import moment from "moment";
  import {mapActions, mapGetters} from "vuex";

  const initialState = () => {
    return {
      saving_request: false,
      alert: false,
      alert_message: '',

      gross_collections: '',
      amount_per_usage: '',
      usage: '',
      other_charges: '0',
      specify: '',
    }
  }
  export default {
    props: {
      kind_of_bill: String,
      category_id: Number,
    },
    setup() {
      return {
        icons: {
          mdiAlertOutline,
        },
      }
    },
    data() {
      return initialState();
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
    },
    methods: {
      done_request() {
        this.saving_request = true
        this.alert = false
        if (this.$refs.form.validate()) {
          var can_proceed = false;
          var total_req = ((parseFloat(this.amount_per_usage) * parseFloat(this.usage)) + parseFloat(this.other_charges));
          var max_req = 0;

          if (this.kind_of_bill === 'ELECTRIC BILL') {
            switch (this.gross_collections) {
              case 'Php 50,000.00 below':
                can_proceed = (total_req <= 1000)
                max_req = 1000;
                break;
              case 'Php 50,001.00 - Php 100,000.00':
                can_proceed = (total_req <= 2000)
                max_req = 2000;
                break;
              case 'Php 100,001.00 - Php 200,000.00':
                can_proceed = (total_req <= 3000)
                max_req = 3000;
                break;
              case 'Php 200,001.00 - Php 500,000.00':
                can_proceed = (total_req <= 5000)
                max_req = 5000;
                break;
              case 'Php 500,001.00 - Php 1,000,000.00':
                can_proceed = (total_req <= 10000)
                max_req = 10000;
                break;
              case 'Php 1,000,001.00 and above':
                can_proceed = (total_req <= 15000)
                max_req = 15000;
                break;
            }
            if (this.category_id != 4) {
              can_proceed = true
            }
          } else {
            can_proceed = this.kind_of_bill === 'WATER BILL'
          }
          if (can_proceed) {
            this.$emit('data', this.amount_per_usage + (this.kind_of_bill === 'ELECTRIC BILL' ? ' KWH(amount) | ' : ' Cubic(amount) | ') + this.usage + (this.kind_of_bill === 'ELECTRIC BILL' ? ' KWH(usage) | ' : ' Cubic(usage) | ') + this.other_charges + ' Charges (' + (this.specify != '' ? this.specify : 'NA') + ') ' + (this.kind_of_bill === 'ELECTRIC BILL' ? '| ' + this.gross_collections + ' GC' : ''), total_req)
            this.saving_request = false
          } else {
            this.$emit('data', this.amount_per_usage + (this.kind_of_bill === 'ELECTRIC BILL' ? ' KWH(amount) | ' : ' Cubic(amount) | ') + this.usage + (this.kind_of_bill === 'ELECTRIC BILL' ? ' KWH(usage) | ' : ' Cubic(usage) | ') + this.other_charges + ' Charges (' + (this.specify != '' ? this.specify : 'NA') + ') ' + (this.kind_of_bill === 'ELECTRIC BILL' ? '| ' + this.gross_collections + ' GC' : ''), max_req)
            this.saving_request = false
            // this.alert = true
            // this.alert_message = 'Your Requested Amount Exceed to the Scheme'
            // this.saving_request = false
          }
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving_request = false
        }
      },
    }
  }
</script>
