<template>
  <div>
    <v-card>
      <v-form ref="form" class="multi-col-validation">
        <v-toolbar dense dark color="primary">
          <v-toolbar-title><h4 class="font-weight-light">EMPLOYEE INCENTIVES</h4>
          </v-toolbar-title>
        </v-toolbar>

        <v-layout row wrap class="align-center mx-2 mt-5">
          <v-flex xs12 md12>
            <v-select
              class="mx-2"
              v-model="month_of"
              dense
              outlined
              label="Month of"
              :items="month_of_items"
              item-text="month_of"
              item-value="id"
              :rules="rules.combobox_rule"
              @change="selected_month"
            ></v-select>
          </v-flex>
          <v-flex xs12 md12>
            <v-select
              class="mx-2"
              v-model="bank"
              dense
              outlined
              label="Bank"
              :items="bank_items"
              item-text="bank_type"
              item-value="bank_type"
              :rules="rules.combobox_rule"
              @change="selected_bank"
            ></v-select>
          </v-flex>
          <v-flex xs12 md12>

            <v-text-field
              class="mx-2"
              v-model="total_raw"
              dense
              label="Total"
              outlined
              readonly
            ></v-text-field>
          </v-flex>

        </v-layout>

        <v-row>
          <!-- alert -->
          <v-col cols="12" v-show="alert">
            <v-alert
              color="warning"
              text
              class="mb-0"
            >
              <div class="d-flex align-start">
                <v-icon color="warning">
                  {{ icons.mdiAlertOutline }}
                </v-icon>

                <div class="ms-3">
                  <p class="text-base font-weight-medium mb-1">
                    {{alert_message}}
                  </p>
                </div>
              </div>
            </v-alert>
          </v-col>
          <v-col cols="12" md="12">
            <v-btn
              class="w-full"
              color="primary"
              @click="done_request"
              v-if="!saving_request"
            >
              Done
            </v-btn>
            <v-progress-circular
              :size=50
              :width="5"
              color="primary"
              indeterminate
              v-else
            ></v-progress-circular>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </div>
</template>
<script>

  import {mdiAlertOutline} from "@mdi/js";
  import {mapActions, mapGetters} from "vuex";

  const initialState = () => {
    return {
      saving_request: false,
      alert: false,
      alert_message: '',

      total: 0,
      total_raw: 0,
      less: 0,
      percentage: '2%',
      computedd: 0,
      total_gross: 0,
      row: 'is_allowance',
      month_of: '',
      name: '',
      bank: '',
      name_items: [],
      month_of_items: [],
      bank_items: [],
      id: 0,
    }
  }
  export default {
    props: {
      title: String,
      category_id: Number,
      branch_id: Number,
      particulars_id: Number,
      particulars_description_items: Array,
    },
    setup() {
      return {
        icons: {
          mdiAlertOutline,
        },
      }
    },
    data() {
      return initialState();
    },
    mounted() {
      this.initialize_data()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
    },
    methods: {
      ...mapActions('salaries_and_wages', ['initialize_salaries_employee']),
      ...mapActions('employee_evaluation_data', ['get_total_not_yet_request_employee_incentives']),
      ...mapActions('request_data', ['check_under_particulars_availability', 'initialization_of_request_bod_allowance', 'released_bod_allowance']),
      initialize_data() {
        this.initialize_salaries_employee()
          .then(response => {
            this.month_of_items = response.data[0].month_of
          })
          .catch(error => {
            console.log(error)
          })
      },
      reset() {
        this.initialize_data()
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      selected_month(value) {
        this.total = 0
        this.get_total_not_yet_request_employee_incentives({
          month_of_id: value
        })
          .then(response => {
            this.bank_items = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      selected_bank(value) {
        this.total = 0
        var index = this.bank_items.map(function (x) {
          return x.bank_type;
        }).indexOf(this.bank)
        if (index != -1) {
          this.total = this.bank_items[index].total
          this.total_raw = this.formatPrice(this.total)
        }
      },
      done_request() {
        this.saving_request = true
        this.alert = false
        if (this.$refs.form.validate()) {
          var index = this.month_of_items.map(function (x) {
            return x.id;
          }).indexOf(this.month_of)
          if (index != -1) {
            this.$emit('data', this.month_of_items[index].month_of + ' # Bank-' + this.bank + ' # Gross-P' + this.total_raw)
            this.$emit('amount', this.total)
            this.saving_request = false
          }
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving_request = false
        }
      },
    }
  }
</script>
