<template>
  <div>
    <v-card>
      <v-form ref="form" class="multi-col-validation">
        <v-toolbar dense dark color="primary">
          <v-toolbar-title><h4 class="font-weight-light">PURCHASE LAND PROPERTY</h4>
          </v-toolbar-title>
        </v-toolbar>

        <v-layout row wrap class="align-center mx-2 mt-5">
          <v-flex xs12 md4>
            <v-select
              class="mx-2"
              v-model="particulars"
              dense
              outlined
              label="Code"
              :items="particulars_items"
              item-value="id"
              item-text="id"
              :rules="rules.combobox_rule"
              @change="selected_pending"
            ></v-select>
          </v-flex>
          <v-flex xs12 md12>
            <v-text-field
              class="mx-2"
              v-model="title_no"
              dense
              label="Title #:"
              outlined
              readonly
            ></v-text-field>
            <v-text-field
              class="mx-2"
              v-model="land_area"
              dense
              label="Land Area"
              outlined
              readonly
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md12>
            <v-text-field
              class="mx-2"
              v-model="location"
              dense
              label="Location"
              outlined
              readonly
            ></v-text-field>
            <v-text-field
              class="mx-2"
              v-model="land_procure"
              dense
              label="Land Procure"
              outlined
            ></v-text-field>
            <v-text-field
              class="mx-2"
              v-model="land_value"
              dense
              label="Land Value"
              outlined
              readonly
            ></v-text-field>
            <v-text-field
              class="mx-2"
              v-model="type_of_property"
              dense
              label="Type of Property"
              outlined
              readonly
            ></v-text-field>
            <v-text-field
              class="mx-2"
              v-model="prev_owner"
              dense
              label="Prev. Owner"
              outlined
              readonly
            ></v-text-field>
            <v-text-field
              class="mx-2"
              v-model="sell_to"
              dense
              label="Sell To"
              outlined
              readonly
            ></v-text-field>
          </v-flex>
        </v-layout>

        <v-row>
          <!-- alert -->
          <v-col cols="12" v-show="alert">
            <v-alert
              color="warning"
              text
              class="mb-0"
            >
              <div class="d-flex align-start">
                <v-icon color="warning">
                  {{ icons.mdiAlertOutline }}
                </v-icon>

                <div class="ms-3">
                  <p class="text-base font-weight-medium mb-1">
                    {{alert_message}}
                  </p>
                </div>
              </div>
            </v-alert>
          </v-col>
          <v-col cols="12" md="12">
            <v-btn
              class="w-full"
              color="primary"
              @click="done_request"
              v-if="!saving_request"
            >
              Done
            </v-btn>
            <v-progress-circular
              :size=50
              :width="5"
              color="primary"
              indeterminate
              v-else
            ></v-progress-circular>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </div>
</template>
<script>

  import {mdiAlertOutline} from "@mdi/js";
  import {mapActions, mapGetters} from "vuex";

  const initialState = () => {
    return {
      saving_request: false,
      alert: false,
      alert_message: '',

      particulars: '',
      particulars_items: [],

      title_no: '',
      land_area: '',
      location: '',
      land_procure: '',
      land_value: 0,
      type_of_property: '',
      prev_owner: '',
      sell_to: '',
    }
  }
  export default {
    props: {
      category_id: Number,
      branch_id: Number,
      particulars_id: Number,
    },
    setup() {
      return {
        icons: {
          mdiAlertOutline,
        },
      }
    },
    data() {
      return initialState();
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
    },
    mounted() {
      this.list_of_land_unpaid()
        .then(response => {
          this.particulars_items = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    methods: {
      ...mapActions('inventory', ['list_of_land_unpaid']),
      selected_pending(value) {
        var index = this.particulars_items.map(function (x) {
          return x.id;
        }).indexOf(value)
        if (index != -1) {
          this.title_no = this.particulars_items[index].land_title_no
          this.land_area = this.particulars_items[index].land_area
          this.location = this.particulars_items[index].location
          this.land_procure = this.particulars_items[index].land_procure
          this.land_value = this.particulars_items[index].land_value
          this.type_of_property = this.particulars_items[index].type_of_property
          this.prev_owner = this.particulars_items[index].pre_owner
          this.sell_to = this.particulars_items[index].sell_to
        }
      },
      done_request() {
        this.saving_request = true
        this.alert = false
        if (this.$refs.form.validate()) {
          this.$emit('data', 'Title No-' + this.title_no + ' # Area-' + this.land_area + ' # Loc.-' + this.location + ' # Proc.-' + this.land_procure + ' # Value.-' + this.land_value + ' # Type-' + this.type_of_property + ' # Prev. Owner-' + this.prev_owner + ' # Sell-' + this.sell_to)
          this.saving_request = false
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving_request = false
        }
      },
    }
  }
</script>
