<template>
  <div>
    <v-card>
      <v-form ref="form" class="multi-col-validation">
        <v-toolbar dense dark color="primary">
          <v-toolbar-title><h4 class="font-weight-light">LOT RENTAL</h4>
          </v-toolbar-title>
        </v-toolbar>

        <v-layout row wrap class="align-center mx-2 mt-5">
          <v-flex xs12 md12>
            <v-select
              class="mx-2"
              v-model="particulars"
              dense
              outlined
              label="Particulars"
              :items="particulars_items"
              item-value="particulars"
              :rules="rules.combobox_rule"
            ></v-select>
          </v-flex>
          <v-flex xs12 md12 v-if="particulars==='New'">
            <v-text-field
              class="mx-2"
              v-model="description"
              dense
              label="Description"
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md12 v-if="particulars!='New'">
            <v-select
              class="mx-2"
              v-model="year"
              dense
              outlined
              label="Year"
              :items="year_items"
              item-value="year"
              item-text="year"
              :rules="rules.combobox_rule"
              @change="selected_year"
            ></v-select>
          </v-flex>
          <v-flex xs12 md6 v-if="particulars!='New'">
            <v-select
              class="mx-2"
              v-model="from_month_of"
              dense
              outlined
              label="From Month Of"
              :items="month_of_items"
              item-value="month_of"
              item-text="month_of"
              :rules="rules.combobox_rule"
              @change="selected_month"
            ></v-select>
          </v-flex>
          <v-flex xs12 md6 v-if="particulars!='New'">
            <v-select
              class="mx-2"
              v-model="to_month_of"
              dense
              outlined
              label="To Month Of"
              :items="month_of_items2"
              item-value="month_of"
              item-text="month_of"
              :rules="rules.combobox_rule"
            ></v-select>
          </v-flex>
          <v-flex xs12 md12>
            <v-autocomplete
              class="mx-2"
              v-model="search"
              :items="search_items"
              :loading="isLoading"
              @keyup.enter="searching($event.target.value)"
              hide-no-data
              item-text="name"
              item-value="id"
              label="Search"
              placeholder="Press Enter to Search "
              :prepend-icon="icons.mdiAccountSearchOutline"
              @change="get_search_items_info"
              outlined
            ></v-autocomplete>
          </v-flex>
        </v-layout>

        <v-row>
          <!-- alert -->
          <v-col cols="12" v-show="alert">
            <v-alert
              color="warning"
              text
              class="mb-0"
            >
              <div class="d-flex align-start">
                <v-icon color="warning">
                  {{ icons.mdiAlertOutline }}
                </v-icon>

                <div class="ms-3">
                  <p class="text-base font-weight-medium mb-1">
                    {{alert_message}}
                  </p>
                </div>
              </div>
            </v-alert>
          </v-col>
          <v-col cols="12" md="12">
            <v-btn
              class="w-full"
              color="primary"
              @click="done_request"
              v-if="!saving_request"
            >
              Done
            </v-btn>
            <v-progress-circular
              :size=50
              :width="5"
              color="primary"
              indeterminate
              v-else
            ></v-progress-circular>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </div>
</template>
<script>

  import {mdiAlertOutline} from "@mdi/js";
  import {mapActions, mapGetters} from "vuex";

  const initialState = () => {
    return {
      saving_request: false,
      alert: false,
      alert_message: '',
      particulars: '',
      particulars_items: ['New', 'Existing', 'Increase', 'Extension'],
      year: '',
      year_items: [],
      from_month_of: '',
      month_of_items: [],
      month_of_items2: [],
      to_month_of: '',
      description: '',

      search: '',
      search_member: '',
      search_items: [],
      credit_to: '',
      isLoading: false,
    }
  }
  export default {
    props: {
      branch_id: Number,
      particulars_id: Number,
    },
    setup() {
      return {
        icons: {
          mdiAlertOutline,
        },
      }
    },
    data() {
      return initialState();
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
    },
    mounted() {
      this.list_of_year_and_month_of_of_particular({
        particulars_id: this.particulars_id,
        branch_id: this.branch_id,
      })
        .then(response => {
          this.month_of_items = []
          this.year_items = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    methods: {
      ...mapActions('request_data', ['check_revolving_fund_availability', 'list_of_year_and_month_of_of_particular']),
      ...mapActions('affiliatee', ['search_all_active_affiliate']),
      searching(value) {
        this.credit_to = ''
        this.isLoading = true
        this.search_items = []
        this.search_all_active_affiliate({
          search_word: value,
          particulars_id: this.is_ftv === 1 ? 266 : this.particulars_id,
          branch_id: this.is_ftv === 1 ? '' : this.branch_id,
        }).then(response => {
          this.isLoading = false
          this.search_items = response.data
          this.search = ''
        })
      },
      get_search_items_info(value) {
        if (this.search_items.length > 0) {
          var index = this.search_items
            .map(function (x) {
              return x.id
            })
            .indexOf(value)
          if (index != -1) {
            this.credit_to = this.search_items[index].name
          } else {
            this.search = ''
            this.credit_to = ''
            this.search_items = []
          }
        } else {
          this.search_items = []
          this.credit_to = ''
          this.search = ''
        }
      },
      selected_year() {
        var index = this.year_items.map(function (x) {
          return x.year;
        }).indexOf(this.year)
        if (index != -1) {
          this.month_of_items = this.year_items[index].month_of
        }
      },
      selected_month(value) {
        this.month_of_items2 = []
        var index = this.month_of_items.map(function (x) {
          return x;
        }).indexOf(value)
        if (index != -1) {
          for (var x = index; x < this.month_of_items.length; x++) {
            var dataaa = this.month_of_items[x]
            this.month_of_items2.push(dataaa)
          }
        }
      },
      done_request() {
        this.saving_request = true
        this.alert = false
        if (this.$refs.form.validate()) {
          if (this.particulars != 'New') {
            this.$emit('data', this.particulars + ' # From-' + this.from_month_of + ' # To-' + this.to_month_of + ' # Lessor-' + this.credit_to)
          } else {
            this.$emit('data', this.particulars + ' # Desc.-' + this.description + ' # Lessor-' + this.credit_to)
          }

          this.saving_request = false
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving_request = false
        }
      },
    }
  }
</script>
