<template>
  <div>
    <v-card>
      <v-form ref="form" class="multi-col-validation">
        <v-toolbar dense dark color="primary">
          <v-toolbar-title><h4 class="font-weight-light">PAYMENT FOR TIE UPS</h4>
          </v-toolbar-title>
        </v-toolbar>

        <v-layout row wrap class="align-center mx-2 mt-5">
          <v-flex xs12 md12>
            <v-radio-group
              v-model="row"
              row
              dense
              class="mx-2"
            >
              Service Render to:
              <v-radio
                label="Member"
                value="is_member"
              ></v-radio>
              <v-radio
                label="Dependent"
                value="is_dependent"
              ></v-radio>
              <v-radio
                label="Assigned"
                value="is_assigned"
              ></v-radio>
            </v-radio-group>
          </v-flex>
          <v-flex xs12 md12>
            <v-radio-group
              v-model="row2"
              row
              dense
              class="mx-2"
            >

              <v-radio
                label="Is With Claims?"
                value="is_with_claims"
              ></v-radio>
              <v-radio
                label="Is Without Claims?"
                value="is_without_claims"
              ></v-radio>
            </v-radio-group>
          </v-flex>
          <v-flex xs12 md12>
            <v-radio-group
              v-model="row3"
              row
              dense
              class="mx-2"
            >
              Type of Death:
              <v-radio
                label="Natural Death"
                value="is_natural_death"
              ></v-radio>
              <v-radio
                label="Accident"
                value="is_accident"
              ></v-radio>
            </v-radio-group>
          </v-flex>
          <v-flex xs12 md4>
            <v-select
              class="mx-2"
              v-model="plan_type"
              dense
              outlined
              label="Plan Type"
              :items="plan_type_items"
              item-value="plan_type"
              item-text="plan_type"
              :rules="rules.combobox_rule"
            ></v-select>
          </v-flex>
          <v-flex xs12 md8>

          </v-flex>
          <v-flex xs12 md4>
            <v-text-field
              class="mx-2"
              v-model="maf_no"
              dense
              label="Maf #"
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
              type="number"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md8>
            <v-text-field
              class="mx-2"
              v-model="members_name"
              dense
              label="Members Name"
              outlined
              :rules="rules.default_max_255_character_and_no_empty_rule"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md12 v-if="row==='is_dependent'||row==='is_assigned'">
            <v-text-field
              class="mx-2"
              v-model="dependent_name"
              dense
              label="Dependent Name"
              outlined
              :rules="rules.default_max_255_character_and_no_empty_rule"
              v-if="row==='is_dependent'"
            ></v-text-field>
            <v-text-field
              v-if="row==='is_assigned'"
              class="mx-2"
              v-model="dependent_name"
              dense
              label="Assigned Name"
              outlined
              :rules="rules.default_max_255_character_and_no_empty_rule"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md4>
            <v-text-field
              class="mx-2"
              v-model="service_balance"
              dense
              label="Service Balance"
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
              type="number"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md2>
            <v-text-field
              class="mx-2"
              v-model="age"
              dense
              label="Age"
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
              type="number"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md3>
            <v-select
              class="mx-2"
              v-model="gender"
              dense
              outlined
              label="Gender"
              :items="['Male','Female']"
              :rules="rules.combobox_rule"
            ></v-select>
          </v-flex>
          <v-flex xs12 md12>
            <v-select
              class="mx-2"
              v-model="status"
              dense
              outlined
              label="Status"
              :items="['TERMINATED','NOT TERMINATED']"
              :rules="rules.combobox_rule"
            ></v-select>
          </v-flex>
          <v-flex xs12 md12 class="mt-15">
            <v-select
              class="mx-2"
              v-model="tie_up"
              dense
              outlined
              label="Tie Up"
              :items="tie_up_items"
              item-value="tie_up_name"
              item-text="tie_up_name"
              :rules="rules.combobox_rule"
              @change="selected_tie_ups"
            ></v-select>
          </v-flex>
          <v-flex xs12 md6>
            <v-text-field
              class="mx-2"
              v-model="account_name"
              dense
              label="Account Name"
              outlined
              readonly
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md6>
            <v-text-field
              class="mx-2"
              v-model="account_no"
              dense
              label="Account #"
              outlined
              readonly
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md4>
            <v-text-field
              class="mx-2"
              v-model="amount"
              dense
              label="Amount"
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
            ></v-text-field>
          </v-flex>


        </v-layout>

        <v-row>
          <!-- alert -->
          <v-col cols="12" v-show="alert">
            <v-alert
              color="warning"
              text
              class="mb-0"
            >
              <div class="d-flex align-start">
                <v-icon color="warning">
                  {{ icons.mdiAlertOutline }}
                </v-icon>

                <div class="ms-3">
                  <p class="text-base font-weight-medium mb-1">
                    {{alert_message}}
                  </p>
                </div>
              </div>
            </v-alert>
          </v-col>
          <v-col cols="12" md="12">
            <v-btn
              class="w-full"
              color="primary"
              @click="done_request"
              v-if="!saving_request"
            >
              Done
            </v-btn>
            <v-progress-circular
              :size=50
              :width="5"
              color="primary"
              indeterminate
              v-else
            ></v-progress-circular>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </div>
</template>
<script>

  import {mdiAlertOutline} from "@mdi/js";
  import {mapActions, mapGetters} from "vuex";

  const initialState = () => {
    return {
      saving_request: false,
      alert: false,
      alert_message: '',

      plan_type: '',
      plan_type_items: [],
      row2: 'is_without_claims',
      row: 'is_member',
      row3: 'is_natural_death',
      amount: '',
      tie_up: '',
      tie_up_items: [],
      account_no: '',
      account_name: '',
      status: '',
      age: 0,
      service_balance: 0,
      members_name: '',
      maf_no: '',
      gender: '',
      dependent_name: '',
    }
  }
  export default {
    props: {
      category_id: Number,
      branch_id: Number,
      particulars_id: Number,
    },
    setup() {
      return {
        icons: {
          mdiAlertOutline,
        },
      }
    },
    data() {
      return initialState();
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', ['month_of']),
    },
    mounted() {
      const data = new FormData()
      data.append('category_id', this.category_id);
      this.initialization_of_request_payment_of_tie_up(data)
        .then(response => {
          this.plan_type_items = response.data[0].plan_type
          this.tie_up_items = response.data[0].tie_ups
        })
        .catch(error => {
          console.log(error)
        })
    },
    methods: {
      ...mapActions('request_data', ['check_payment_for_tie_up_availability', 'initialization_of_request_payment_of_tie_up']),
      selected_tie_ups(value) {
        var index = this.tie_up_items.map(function (x) {
          return x.tie_up_name;
        }).indexOf(value)
        if (index != -1) {
          this.account_name = this.tie_up_items[index].account_name
          this.account_no = this.tie_up_items[index].account_no
          this.amount = this.tie_up_items[index].amount
        }
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      done_request() {
        this.saving_request = true
        this.alert = false
        if (this.$refs.form.validate()) {
          const data = new FormData()
          data.append('maf_no', this.maf_no+ '-' + this.plan_type);
          data.append('branch_id', this.branch_id);
          data.append('particulars_id', this.particulars_id);
          this.check_payment_for_tie_up_availability(data)
            .then(response => {
              if (response.data > 0) {
                this.alert = true
                this.alert_message = 'MAF # '+this.maf_no + ' IS ALREADY ENCODED!!!'
                this.saving_request = false
              } else {
                this.$emit('data', (this.row === 'is_member' ? 'Member' : this.row === 'is_dependent' ? 'Dependent' : 'Assigned') + ' # Mafno.-' + this.maf_no + '-' + this.plan_type + ' # MemName-' + this.members_name + ' # Cadaver-' + (this.row === 'is_member' ? this.members_name : this.dependent_name) + ' # Bal.-P' + this.formatPrice(this.service_balance) + ' # M-' + this.month_of + ' # C-' + (this.row2 === 'is_with_claims' ? 'YES' : 'NO') + ' # TIE_N-' + this.tie_up + ' # TIE_ACC-' +this.account_name+'('+ this.account_no + ') # AGE-' + this.age + ' # S-' + this.status + ' # DEA-' + (this.row3 === 'is_natural_death' ? 'NATURAL' : 'ACCIDENT') + ' # GEN-' + this.gender)
                this.$emit('maf_no', this.maf_no + '-' + this.plan_type);
                this.$emit('amount', this.amount);
                this.saving_request = false
              }
            })
            .catch(error => {
              console.log(error)
            })
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving_request = false
        }
      },
    }
  }
</script>
