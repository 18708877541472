<template>
  <div>
    <v-card>
      <v-form ref="form" class="multi-col-validation">
        <v-toolbar dense dark color="primary">
          <v-toolbar-title><h4 class="font-weight-light">{{title}}</h4>
          </v-toolbar-title>
        </v-toolbar>

        <v-layout row wrap class="align-center mx-2 mt-5">
          <v-flex xs12 md12>
            <v-radio-group
              v-model="row"
              row
              dense
              class="mx-2"
              @change="reset"
            >

              <v-radio
                label="15th"
                value="is_allowance"
              ></v-radio>
              <v-radio
                label="5th/20th"
                value="is_salary"
              ></v-radio>
            </v-radio-group>
          </v-flex>
          <v-flex xs12 md12>
            <v-select
              v-if="title==='EMPLOYEE ALLOWANCES'"
              class="mx-2"
              v-model="name"
              dense
              outlined
              label="Name of the Employee"
              :items="name_items"
              item-text="name"
              item-id="name"
              :rules="rules.combobox_rule"
              @change="selected_name"
            ></v-select>
            <v-select
              v-else
              class="mx-2"
              v-model="name"
              dense
              outlined
              label="Name of the Board"
              :items="name_items"
              item-text="name"
              item-id="name"
              :rules="rules.combobox_rule"
              @change="selected_name"
            ></v-select>
          </v-flex>
          <v-flex xs12 md12>
            <v-text-field
              v-if="row==='is_allowance'"
              class="mx-2"
              v-model="month_of"
              dense
              label="Month Of"
              outlined
              readonly
            ></v-text-field>
            <v-text-field
              v-else
              class="mx-2"
              v-model="month_of"
              dense
              label="Month Of"
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md6>
            <v-text-field
              v-if="row==='is_allowance'"
              class="mx-2"
              v-model="total_gross"
              dense
              label="Total Gross"
              outlined
              readonly
            ></v-text-field>
            <v-text-field
              v-else
              class="mx-2"
              v-model="total_gross"
              dense
              label="Total Gross"
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
              type="number"
              @keyup="computation"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md6 v-if="row==='is_salary'">
            <v-text-field
              class="mx-2"
              v-model="computedd"
              dense
              label="Computed"
              outlined
              readonly
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md12 v-if="row==='is_salary'">
            <v-text-field
              class="mx-2"
              v-model="percentage"
              dense
              label="Percentage (%)"
              outlined
              readonly
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md12>
            <v-text-field
              v-if="row==='is_allowance'"
              class="mx-2"
              v-model="less"
              dense
              label="Less"
              outlined
              readonly
            ></v-text-field>
            <v-text-field
              v-else
              class="mx-2"
              v-model="less"
              dense
              label="Less"
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
              type="number"
              @keyup="computation"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md12>
            <v-text-field
              class="mx-2"
              v-model="total"
              dense
              label="Total"
              outlined
              readonly
            ></v-text-field>
          </v-flex>
        </v-layout>

        <v-row>
          <!-- alert -->
          <v-col cols="12" v-show="alert">
            <v-alert
              color="warning"
              text
              class="mb-0"
            >
              <div class="d-flex align-start">
                <v-icon color="warning">
                  {{ icons.mdiAlertOutline }}
                </v-icon>

                <div class="ms-3">
                  <p class="text-base font-weight-medium mb-1">
                    {{alert_message}}
                  </p>
                </div>
              </div>
            </v-alert>
          </v-col>
          <v-col cols="12" md="12">
            <v-btn
              class="w-full"
              color="primary"
              @click="done_request"
              v-if="!saving_request"
            >
              Done
            </v-btn>
            <v-progress-circular
              :size=50
              :width="5"
              color="primary"
              indeterminate
              v-else
            ></v-progress-circular>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </div>
</template>
<script>

  import {mdiAlertOutline} from "@mdi/js";
  import {mapActions, mapGetters} from "vuex";

  const initialState = () => {
    return {
      saving_request: false,
      alert: false,
      alert_message: '',

      total: 0,
      total_raw: 0,
      less: 0,
      percentage: '2%',
      computedd: 0,
      total_gross: 0,
      row: 'is_allowance',
      month_of: '',
      name: '',
      name_items: [],
      id: 0,
    }
  }
  export default {
    props: {
      title: String,
      category_id: Number,
      branch_id: Number,
      particulars_id: Number,
      particulars_description_items: Array,
    },
    setup() {
      return {
        icons: {
          mdiAlertOutline,
        },
      }
    },
    data() {
      return initialState();
    },
    mounted() {
      this.initialize_data()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
    },
    methods: {
      ...mapActions('request_data', ['check_under_particulars_availability', 'initialization_of_request_bod_allowance', 'released_bod_allowance']),
      initialize_data() {
        const data = new FormData()
        data.append('search', this.row);
        data.append('title', this.title);
        this.initialization_of_request_bod_allowance(data)
          .then(response => {
            this.name_items = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      reset() {
        this.initialize_data()
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      selected_name(value) {
        this.total_gross = ''
        this.month_of = ''
        this.less = 0
        this.total = 0
        this.total_raw = 0
        this.id = 0
        if (this.row === 'is_allowance') {
          var index = this.name_items.map(function (x) {
            return x.name;
          }).indexOf(value)
          if (index != -1) {
            console.log(this.name_items[index])
            this.total_gross = this.formatPrice(parseFloat(this.name_items[index].data.net_income) + parseFloat(this.name_items[index].data.allowances))
            this.month_of = this.name_items[index].data.transaction_month.month_of
            this.less = this.formatPrice(this.name_items[index].data.allowances)
            this.total = this.formatPrice(this.name_items[index].data.net_income)
            this.total_raw = this.name_items[index].data.net_income + ''
            this.id = this.name_items[index].data.id
          }
        }
      },
      computation() {
        if (this.row === 'is_salary') {
          var com = parseFloat(this.total_gross) * 0.02
          this.computedd = this.formatPrice(com)
          this.total = this.formatPrice(com - parseFloat(this.less))
          this.total_raw = (com - parseFloat(this.less))
        }
      },
      done_request() {
        this.saving_request = true
        this.alert = false
        if (this.$refs.form.validate()) {
          if (this.row === 'is_allowance') {
            const data = new FormData()
            data.append('id', this.id);
            this.released_bod_allowance(data)
          }
          this.$emit('data', this.name + ' # ' + this.month_of + ' # Gross-P' + this.total_gross + ' # ' + this.percentage)
          this.$emit('amount', this.total_raw)
          this.saving_request = false
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving_request = false
        }
      },
    }
  }
</script>
