<template>
  <div>
    <v-card>
      <v-form ref="form" class="multi-col-validation">
        <v-toolbar dense dark color="primary">
          <v-toolbar-title><h4 class="font-weight-light">WATER BILL</h4>
          </v-toolbar-title>
        </v-toolbar>

        <v-layout row wrap class="align-center mx-2 mt-5">
          <v-flex xs12 md12>
            <v-text-field
              class="mx-2"
              v-model="description"
              dense
              label="Description"
              outlined
              :rules="rules.default_max_100_character_and_no_empty_rule"
              readonly
              @click="details_of_bill"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md12>
            <v-select
              class="mx-2"
              v-model="account"
              dense
              outlined
              label="Account"
              :items="account_items"
              item-value="id"
              item-text="name"
              :rules="rules.combobox_rule"
              @change="selected_year"
            ></v-select>
          </v-flex>
          <v-flex xs12 md6>
            <v-select
              class="mx-2"
              v-model="year"
              dense
              outlined
              label="Year"
              :items="year_items"
              item-value="year"
              item-text="year"
              :rules="rules.combobox_rule"
              @change="selected_year"
            ></v-select>
          </v-flex>
          <v-flex xs12 md6>
            <v-select
              class="mx-2"
              v-model="month_of"
              dense
              outlined
              label="Month Of"
              :items="month_of_items"
              item-value="month_of"
              item-text="month_of"
              :rules="rules.combobox_rule"
            ></v-select>
          </v-flex>
        </v-layout>

        <v-row>
          <!-- alert -->
          <v-col cols="12" v-show="alert">
            <v-alert
              color="warning"
              text
              class="mb-0"
            >
              <div class="d-flex align-start">
                <v-icon color="warning">
                  {{ icons.mdiAlertOutline }}
                </v-icon>

                <div class="ms-3">
                  <p class="text-base font-weight-medium mb-1">
                    {{alert_message}}
                  </p>
                </div>
              </div>
            </v-alert>
          </v-col>
          <v-col cols="12" md="12">
            <v-btn
              class="w-full"
              color="primary"
              @click="done_request"
              v-if="!saving_request"
            >
              Done
            </v-btn>
            <v-progress-circular
              :size=50
              :width="5"
              color="primary"
              indeterminate
              v-else
            ></v-progress-circular>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
    <v-dialog v-model="is_usage_click" persistent max-width="50%">
      <UsageInformation
        v-on:data="on_save_usage"
        :key="this.key"
        :category_id="this.category_id"
        :kind_of_bill="'WATER BILL'"
      ></UsageInformation>
      <v-btn color="error" @click="is_usage_click = false"> Close</v-btn>
    </v-dialog>
  </div>
</template>
<script>

  import {mdiAlertOutline} from "@mdi/js";
  import {mapActions, mapGetters} from "vuex";
  import UsageInformation
    from "@/views/file_interface/create/request/components/components_electric_water_bill/UsageInformation";

  const initialState = () => {
    return {
      key: 0,
      saving_request: false,
      alert: false,
      alert_message: '',
      particulars: '',
      account: '',
      account_items: [],
      particulars_items: ['New', 'Existing', 'Increase', 'Extension'],
      year: '',
      year_items: [],
      month_of: '',
      month_of_items: [],
      description: '',

      is_usage_click: false,
      amount_req: 0,
    }
  }
  export default {
    components: {
      UsageInformation,
    },
    props: {
      branch_id: Number,
      category_id: Number,
      particulars_id: Number,
    },
    setup() {
      return {
        icons: {
          mdiAlertOutline,
        },
      }
    },
    data() {
      return initialState();
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
    },
    mounted() {
      this.list_of_year_and_month_of()
        .then(response => {
          this.month_of_items = []
          this.year_items = response.data
        })
        .catch(error => {
          console.log(error)
        })
      this.list_of_affiliates_bills_per_branch({
        branch_id: this.branch_id,
        particulars_id: this.particulars_id,
      })
        .then(response => {
          this.account_items = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    methods: {
      ...mapActions('request_data', ['check_bills_availability', 'list_of_year_and_month_of']),
      ...mapActions('affiliatee', ['list_of_affiliates_bills_per_branch']),
      selected_year() {
        var index = this.year_items.map(function (x) {
          return x.year;
        }).indexOf(this.year)
        if (index != -1) {
          this.month_of_items = this.year_items[index].month_of
        }
      },
      details_of_bill() {
        this.is_usage_click = true
        this.key++
      },
      on_save_usage(value, amountt) {
        this.description = value
        this.amount_req = amountt
        this.is_usage_click = false
      },
      done_request() {
        this.saving_request = true
        this.alert = false
        if (this.$refs.form.validate()) {
          var biller_info = this.account_items[this.account_items.map(function (x) {
            return x.id;
          }).indexOf(this.account)].name
          const data = new FormData()
          data.append('month_of', this.month_of);
          data.append('branch_id', this.branch_id);
          data.append('particulars_id', this.particulars_id);
          data.append('biller_info', biller_info);
          this.check_bills_availability(data)
            .then(response => {
              if (response.data > 0) {
                this.alert = true
                this.alert_message = this.month_of + ' # Desc.-' + this.description + ' ALREADY ENCODED!!!'
                this.saving_request = false
              } else {
                this.$emit('data', this.description + ' # Month-' + this.month_of + ' # Biller-' + biller_info)
                this.$emit('amount', this.amount_req)
                this.saving_request = false
              }
            })
            .catch(error => {
              console.log(error)
            })
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving_request = false
        }
      },
    }
  }
</script>
