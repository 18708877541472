<template>
  <div>
    <v-card>
      <v-form ref="form" class="multi-col-validation">
        <v-toolbar dense dark color="primary">
          <v-toolbar-title><h4 class="font-weight-light">PURCHASE CASKET</h4>
          </v-toolbar-title>
        </v-toolbar>

        <v-layout row wrap class="align-center mx-2 mt-5">
          <v-flex xs12 md12>
            <v-select
              v-if="category_id===4"
              class="mx-2"
              v-model="tie_up_name"
              dense
              outlined
              label="Tie Up"
              :items=tie_up_items
              item-text="tie_up_name"
              item-value="tie_up_name"
              :rules="rules.combobox_rule"
            ></v-select>
            <v-select
              class="mx-2"
              v-model="casket_type"
              dense
              outlined
              label="Casket Type"
              :items=casket_type_items
              item-text="casket_type"
              item-value="casket_type"
              :rules="rules.combobox_rule"
            ></v-select>
            <v-flex xs12 md3>
              <v-text-field
                class="mx-2"
                v-model="po_no"
                dense
                label="PO#"
                outlined
                :rules="rules.default_max_45_character_and_no_empty_rule"
              ></v-text-field>
            </v-flex>
          </v-flex>
          <v-flex xs12 md3>
            <v-text-field
              class="mx-2"
              v-model="quantity"
              dense
              label="Quantity"
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
              type="number"
              @keyup="calculate_commission"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md3>
            <v-text-field
              class="mx-2"
              v-model="price"
              dense
              label="Price by pcs."
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
              type="number"
              @keyup="calculate_commission"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md3>
            <v-text-field
              class="mx-2"
              v-model="total"
              dense
              label="Total"
              outlined
              readonly
            ></v-text-field>
          </v-flex>
        </v-layout>

        <v-row>
          <!-- alert -->
          <v-col cols="12" v-show="alert">
            <v-alert
              color="warning"
              text
              class="mb-0"
            >
              <div class="d-flex align-start">
                <v-icon color="warning">
                  {{ icons.mdiAlertOutline }}
                </v-icon>

                <div class="ms-3">
                  <p class="text-base font-weight-medium mb-1">
                    {{alert_message}}
                  </p>
                </div>
              </div>
            </v-alert>
          </v-col>
          <v-col cols="12" md="12">
            <v-btn
              class="w-full"
              color="primary"
              @click="done_request"
              v-if="!saving_request"
            >
              Done
            </v-btn>
            <v-progress-circular
              :size=50
              :width="5"
              color="primary"
              indeterminate
              v-else
            ></v-progress-circular>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </div>
</template>
<script>

  import {mdiAlertOutline} from "@mdi/js";
  import {mapActions, mapGetters} from "vuex";

  const initialState = () => {
    return {
      saving_request: false,
      alert: false,
      alert_message: '',
      po_no: '',
      casket_type_items: [],
      tie_up_name: '',
      tie_up_items: [],
      callers_commision: 0,
      quantity: 0,
      price: 0,

      isLoading: false,
      total: 0,
      casket_type: '',
    }
  }
  export default {
    props: {
      category_id: Number,
      branch_id: Number,
      particulars_id: Number,
    },
    setup() {
      return {
        icons: {
          mdiAlertOutline,
        },
      }
    },
    mounted() {
      this.list_of_casket()
        .then(response => {
          this.casket_type_items = response.data
        })
        .catch(error => {
          console.log(error)
        })
      const data = new FormData()
      data.append('is_active', 1);
      this.list_of_tie_ups(data)
        .then(response => {
          this.tie_up_items = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    data() {
      return initialState();
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
    },
    methods: {
      ...mapActions('request_data', ['list_of_casket', 'check_under_particulars_availability_have_branch']),
      ...mapActions('tie_ups', ['list_of_tie_ups']),
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      calculate_commission() {
        this.total = parseFloat(this.quantity) * parseFloat(this.price)
      },
      done_request() {
        this.saving_request = true
        this.alert = false
        if (this.$refs.form.validate()) {
          const data = new FormData()
          data.append('particulars_id', this.particulars_id);
          data.append('particulars', this.po_no);
          data.append('month_of', 'Cskt-'+this.casket_type+' #');
          data.append('branch_id', this.branch_id);
          this.check_under_particulars_availability_have_branch(data)
            .then(response => {
              if (response.data > 0) {
                this.alert = true
                this.alert_message = this.po_no + ' ' + this.casket_type + ' ALREADY ENCODED!!!'
                this.saving_request = false
              } else {
                if (this.category_id === 4) {
                  this.$emit('data', this.tie_up_name + ' # PO.-' + this.po_no + ' # Cskt-' + this.casket_type + ' # Qty.' + this.quantity + ' # P' + this.formatPrice(this.total))
                } else {
                  this.$emit('data', 'PO.-' + this.po_no + ' # Cskt-' + this.casket_type + ' # Qty.' + this.quantity + ' # P' + this.formatPrice(this.total))
                }
                this.saving_request = false
              }
            })
            .catch(error => {
              console.log(error)
            })
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving_request = false
        }
      },
    }
  }
</script>
