<template>
  <div>
    <v-card>
      <v-form ref="form" class="multi-col-validation">
        <v-toolbar dense dark color="primary">
          <v-toolbar-title><h4 class="font-weight-light">BRANCH EXPENSE</h4>
          </v-toolbar-title>
        </v-toolbar>

        <v-layout row wrap class="align-center mx-2 mt-5">
          <v-flex xs12 md12>
            <v-select
              class="mx-2"
              v-model="particulars"
              dense
              outlined
              label="Particulars"
              :items="particulars_description_items"
              item-text="particulars"
              item-id="particulars"
              :rules="rules.combobox_rule"
              @change="selected_particulars"
            ></v-select>
          </v-flex>
          <v-flex xs12 md12 v-if="particulars==='Stuff House'">
            <v-autocomplete
              class="mx-2"
              v-model="affiliate"
              :items="affiliate_items"
              :loading="isLoading"
              @keyup.enter="searching($event.target.value)"
              hide-no-data
              hide-selected
              item-text="name"
              item-value="id"
              label="Search"
              placeholder="Press Enter to Search "
              :prepend-icon="icons.mdiAccountSearchOutline"
              outlined
              @change="get_search_items_info"
            ></v-autocomplete>
          </v-flex>
          <v-flex xs12 md6>
            <v-select
              class="mx-2"
              v-model="year"
              dense
              outlined
              label="Year"
              :items="year_items"
              item-value="year"
              item-text="year"
              :rules="rules.combobox_rule"
              @change="selected_year"
            ></v-select>
          </v-flex>
          <v-flex xs12 md6>
            <v-select
              class="mx-2"
              v-model="month_of"
              dense
              outlined
              label="Month Of"
              :items="month_of_items"
              item-value="month_of"
              item-text="month_of"
              :rules="rules.combobox_rule"
            ></v-select>
          </v-flex>
        </v-layout>

        <v-row>
          <!-- alert -->
          <v-col cols="12" v-show="alert">
            <v-alert
              color="warning"
              text
              class="mb-0"
            >
              <div class="d-flex align-start">
                <v-icon color="warning">
                  {{ icons.mdiAlertOutline }}
                </v-icon>

                <div class="ms-3">
                  <p class="text-base font-weight-medium mb-1">
                    {{alert_message}}
                  </p>
                </div>
              </div>
            </v-alert>
          </v-col>
          <v-col cols="12" md="12">
            <v-btn
              class="w-full"
              color="primary"
              @click="done_request"
              v-if="!saving_request"
            >
              Done
            </v-btn>
            <v-progress-circular
              :size=50
              :width="5"
              color="primary"
              indeterminate
              v-else
            ></v-progress-circular>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </div>
</template>
<script>

  import {mdiAlertOutline} from "@mdi/js";
  import {mapActions, mapGetters} from "vuex";

  const initialState = () => {
    return {
      saving_request: false,
      alert: false,
      alert_message: '',
      particulars: '',
      particulars_items: ['New', 'Existing', 'Increase', 'Extension'],
      year: '',
      year_items: [],
      month_of: '',
      month_of_items: [],
      description: '',

      isLoading: false,
      affiliate: '',
      affiliate_items: [],
      particulars_description_id: 0,
      affilate_name: '',
    }
  }
  export default {
    props: {
      category_id: Number,
      branch_id: Number,
      particulars_id: Number,
      particulars_description_items: Array,
    },
    setup() {
      return {
        icons: {
          mdiAlertOutline,
        },
      }
    },
    data() {
      return initialState();
    },
    mounted() {
      this.list_of_year_and_month_of()
        .then(response => {
          this.month_of_items = []
          this.year_items = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
    },
    methods: {
      ...mapActions('request_data', ['check_stuff_house_under_branch_expense_availability', 'list_of_year_and_month_of', 'check_under_particulars_availability']),
      ...mapActions('affiliatee', ['search_all_active_affiliate',]),
      ...mapActions('request_particulars', ['search_particulars',]),
      selected_year() {
        var index = this.year_items.map(function (x) {
          return x.year;
        }).indexOf(this.year)
        if (index != -1) {
          this.month_of_items = this.year_items[index].month_of
        }
      },
      selected_particulars() {
        this.affiliate_items = []
        switch (this.particulars) {
          case "Stuff House": {
            this.particulars_description_id = 0
            this.search_particulars({
              category_id: this.category_id,
              particulars: this.particulars,
            })
              .then(response => {
                this.particulars_description_id = response.data
              })
          }
            break;
          default:
            break;
        }
      },
      searching(value) {
        this.isLoading = true
        this.search_all_active_affiliate({
          search_word: value,
          particulars_id: this.particulars_description_id,
        })
          .then(response => {
            this.isLoading = false
            this.affiliate_items = response.data
          })
      },
      get_search_items_info(value) {
        if (this.affiliate_items.length > 0) {
          var index = this.affiliate_items.map(function (x) {
            return x.id;
          }).indexOf(value)
          if (index != -1) {
            this.affilate_name = this.affiliate_items[index].name
          } else {
            this.affilate_name = ''
          }
        } else {
          this.affilate_name = ''
        }
      },
      done_request() {
        this.saving_request = true
        this.alert = false
        if (this.$refs.form.validate()) {
          const data = new FormData()
          data.append('particulars_id', this.particulars_id);
          data.append('month_of', this.month_of);
          data.append('particulars', this.particulars);
          if (this.particulars === 'Stuff House') {
            data.append('name', this.affilate_name);
            this.check_stuff_house_under_branch_expense_availability(data)
              .then(response => {
                if (response.data > 0) {
                  this.alert = true
                  this.alert_message = this.month_of + ' BILL # For-' + this.affilate_name + ' ALREADY ENCODED!!!'
                  this.saving_request = false
                } else {
                  this.$emit('data', this.particulars + ' # Desc.-' + this.month_of + ' BILL # For-' + this.affilate_name)
                  this.saving_request = false
                }
              })
              .catch(error => {
                console.log(error)
              })
          } else {
            this.check_under_particulars_availability(data)
              .then(response => {
                if (response.data > 0) {
                  this.alert = true
                  this.alert_message = this.month_of + ' BILL # For-' + this.particulars + ' ALREADY ENCODED!!!'
                  this.saving_request = false
                } else {
                  this.$emit('data', this.particulars + ' # Desc.-' + this.month_of + ' BILL')
                  this.saving_request = false
                }
              })
              .catch(error => {
                console.log(error)
              })
          }
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving_request = false
        }
      },
    }
  }
</script>
